import React from "react";
import QRCode from "react-qr-code";
import { FcApproval, FcCancel } from "react-icons/fc";
const StudentCard = ({ student }) => {
  let { english_name, batch, group,guest, occupation, blood_group, image,status,unqId,trx_id,payment_medium,payable_amount,gender,phone } =
    student;
   
    let myStr = image;
    let newStr = myStr.replace("http://res.cloudinary.com/", "");

    const fff = "https://mrbimg.com/allimageschool/"+newStr.substring(newStr.lastIndexOf("/") + 1);
    // console.log(fff);
  return (
    
    <div className={`relative pb-10 bg-slate-800 bg-blend-overlay rounded-2xl text-center `} style={{backgroundImage: `url("https://i.ibb.co/TT5jP1c/school.jpg")`,backgroundPosition:'center',backgroundSize:'cover'}}>
      <div className="mx-auto h-48 w-48 border border-pink-400 rounded-full my-4 p-2">
     
        <img className="w-full h-full rounded-full" src={fff} alt='' />
      </div>
      <div className="font-extrabold">{status === 'verified' ? <span className="text-green-500 flex justify-center items-center gap-1"> <span>Verified</span> <FcApproval></FcApproval></span> : <span className="text-yellow-500 flex justify-center items-center gap-1"> <span>Pending</span> <FcCancel></FcCancel></span> }</div>
      
      <small className="text-white font-bold text-lg block">#{unqId}</small>
      <h3 className="text-white text-xl font-bold">{english_name}</h3>
      {gender === 'Male' ? <h4 className="text-white text-sm font-bold">{phone}</h4> : '' }
      
      <h5 className="text-base text-white">
        {batch}, {group}
      </h5>
      <h6 className="text-sm text-white">{occupation}</h6>
      <h6 className="text-sm text-white">{blood_group}</h6>
      <div>
        <QRCode
            className="h-24 w-24 mx-auto"
          size={256}
          value={` ID: #${unqId} \n Name: ${english_name} \n Batch: ${batch} \n Blood Group: ${blood_group} \n Payment Media: ${payment_medium} \n Amount: ${payable_amount} \n TrxId: ${trx_id} \n Guest: ${guest} \n Registration Status: ${status} \n`}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
};

export default StudentCard;
