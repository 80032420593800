import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Loader from "../../Shared/Loader/Loader";
import * as XLSX from "xlsx/xlsx.mjs";

const AllStudent = () => {
  const {
    data: students = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["students"],
    queryFn: async () => {
      // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
      const res = await fetch(`https://rani-bhobani-server.vercel.app/all-student`, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });
  const excelExport = () => {
    let Heading = [['Name', 'Phone', 'Batch' ,'Profession' ]];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    XLSX.utils.sheet_add_json(ws, students, { origin: 'A2', skipHeader: true });


    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "Rani Bhobani.xlsx");
  };

  return (
    <div>
      <button
        className="btn btn-outline px-4 py-2 mx-auto  text-white rounded-md shadow-lg block bg-red-500"
        onClick={excelExport}
      >
        Export to Excel
      </button>
      <table className="w-full">
        <thead>
          <tr>
            <th>SL</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Profession</th>
            <th>Batch</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {students.map((shirt, idx) => (
            <tr
              key={idx}
              className="backdrop-blur-md bg-blue-200 border-b-4 text-black"
            >
              <td>{idx + 1}</td>
              <td>{shirt.english_name}</td>
              <td>{shirt.phone}</td>
              <td>{shirt.occupation}</td>
              <td>{shirt.batch}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllStudent;
