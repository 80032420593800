import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const ProgramDetails = () => {
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  useEffect(() => fire(), []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);
  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: "fixed",
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      />
      <div className='w-full md:w-1/2 md:mx-auto px-5 py-8 shadow-md rounded-md  bg-[url("https://images.unsplash.com/photo-1508898578281-774ac4893c0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80")] bg-no-repeat bg-cover bg-slate-600 bg-blend-overlay'>
        <h3 className="text-center text-orange-400 text-2xl font-bold my-4">
          <i>অনুষ্ঠান সূচী</i>
        </h3>
        <div>
          <h4 className="font-bold text-red-600 text-md text-center">
            ২৩ এপ্রিল ২০২৩
          </h4>
          <table className="w-full backdrop-blur-xl">
            <thead>
              <tr className="text-center">
                <th className="w-1/2  text-white">
                  {" "}
                  ইভেন্টের নাম
                </th>
                <th className="w-1/2 text-white"> সময় </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center" data-aos="fade-up" data-aos-duration="500">
                <td className="text-sm  text-green-600">
                  রেজিস্ট্রেশন কার্ড ও গিফট হ্যাম্পার বিতরণ
                </td>
                <td className="text-sm  text-green-600">
                  দুপুর ২টা-সন্ধ্যা ৬টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-duration="600">
                <td className="text-sm  text-red-400">
                  চা বিরতি
                </td>
                <td className="text-sm  text-red-400">
                  সন্ধ্যা ৬টা-৭টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-duration="700">
                <td className="text-sm  text-blue-400">
                  ফায়ারওয়ার্কস এবং অতিথিদের উপস্থিতিতে পুনর্মিলনীর শুভারম্ভ।{" "}
                </td>
                <td className="text-sm  text-blue-400">
                  সন্ধ্যা ৭টা-রাত ৯টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-duration="800">
                <td className="text-sm  text-yellow-400">
                  {" "}
                  সাংস্কৃতিক অনুষ্ঠান ১ম পর্ব
                </td>
                <td className="text-sm font-extrabold text-yellow-400">
                  রাত ৯টা-১২টা
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="my-2">
          <h4 className="font-bold text-red-600 text-md text-center">
            ২৪ এপ্রিল ২০২৩
          </h4>
          <table className="w-full backdrop-blur-xl">
          <thead>
              <tr className="text-center">
                <th className="w-1/2  text-white">
                  {" "}
                  ইভেন্টের নাম
                </th>
                <th className="w-1/2 text-white"> সময় </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="900" data-aos-duration="500">
                <td className="text-sm  text-green-600">
                  স্কুল প্রাঙ্গণে প্রবেশ
                </td>
                <td className="text-sm  text-green-600">
                  সকাল ৮টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="600">
                <td className="text-sm  text-red-400">
                 
                  আনুষ্ঠানিক শুভ উদ্বোধন
                </td>
                <td className="text-sm  text-red-400">
                সকাল ৯টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1100" data-aos-duration="700">
                <td className="text-sm  text-blue-400">
                  আনন্দ শোভাযাত্রা ও শহর পরিভ্রমণ{" "}
                </td>
                <td className="text-sm  text-blue-400">
                  সকাল ১১টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1200" data-aos-duration="800">
                <td className="text-sm  text-yellow-400">
                  {" "}
                  অ্যালামনাই এসোসিয়েশন গঠন নিয়ে আলোচনা
                </td>
                <td className="text-sm text-yellow-400">
                  দুপুর ১২টা-১টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1300" data-aos-duration="900">
                <td className="text-sm  text-red-400">
                  {" "}
                  নামাজ বিরতি
                </td>
                <td className="text-sm  text-red-400">
                  দুপুর ১টা-২টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1400" data-aos-duration="1000">
                <td className="text-sm font-extrabold text-violet-400">
                  {" "}
                  মধ্যাহ্নভোজ
                </td>
                <td className="text-sm  text-violet-400">
                  দুপুর ২টা-৩টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1500" data-aos-duration="1100">
                <td className="text-sm  text-orange-400">
                  {" "}
                  আলোচনা, স্মৃতিচারণ, আড্ডা
                </td>
                <td className="text-sm  text-orange-400">
                  বিকাল ৩টা-৬টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1600" data-aos-duration="1200">
                <td className="text-sm  text-cyan-400">
                  {" "}
                  সাংস্কৃতিক অনুষ্ঠান ২য় পর্ব
                </td>
                <td className="text-sm  text-cyan-400">
                সন্ধ্যা ৬টা-রাত ৮টা
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1700" data-aos-duration="1400">
                <td className="text-sm  text-teal-400">
                  {" "}
                  র‍্যাফেল ড্র
                </td>
                <td className="text-sm  text-teal-400">
                  রাত ৮টা-৯:৩০ মিনিট
                </td>
              </tr>
              <tr className="text-center" data-aos="fade-up" data-aos-delay="1800" data-aos-duration="1500">
                <td className="text-sm  text-pink-400">
                  {" "}
                  কনসার্ট
                </td>
                <td className="text-sm  text-pink-400">
                  রাত ১০-১২টা
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-center text-sm text-red-500">বিঃদ্রঃ
পূর্ব ঘোষণা ছাড়াই যেকোনো সময় পরিস্থিতি বিবেচনায় উক্ত অনুষ্ঠান সূচি পরিবর্তন ও পরিবর্ধন হতে পারে।</p>
      </div>
    </>
  );
};

export default ProgramDetails;
