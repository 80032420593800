import React from 'react';
import { MdBloodtype,MdMenuBook } from "react-icons/md";




const DirectoryCard = ({person}) => {
    const {english_name,occupation,phone,blood_group,image} = person;
    let myStr = image;
    let newStr = myStr.replace("http://res.cloudinary.com/", "");

    const fff = "https://mrbimg.com/allimageschool/"+newStr.substring(newStr.lastIndexOf("/") + 1);
    return (
        <div className='flex items-center gap-4 h-24'>
            <div className='w-2/5'>
                <img src={fff} alt="" className='w-full h-24 object-cover'/>
            </div>
            <div className='w-3/5'>
                <h2>{english_name}</h2>
                <h3>{occupation}</h3>
                <h3>{phone}</h3>
                <div className='flex items-center'><div className='flex items-center'> <MdBloodtype></MdBloodtype>  {blood_group}</div></div>
            </div>
        </div>
    );
};

export default DirectoryCard;