import { useQuery } from "@tanstack/react-query";
import React from "react";
import Graph from "./Graph/Graph";

const Stats = () => {
  const {
    data: studentCount,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["studentCount"],
    queryFn: async () => {
      try {
        const res = await fetch("https://rani-bhobani-server.vercel.app/studentCount", {
          headers: {
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const data = await res.json();

        return data;
      } catch (error) {}
    },
  });

  if (isLoading) {
    return <h2>Loading...</h2>;
  }
  // console.log(studentCount.guestCount);
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="px-4 py-3 rounded-lg shadow-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white">
          <div>
            <h5>Total Registration (Unverified)</h5>
            <h3 className="font-extrabold text-2xl">{studentCount.total}</h3>
          </div>
          
        </div>
        <div className="px-4 py-3 rounded-lg shadow-lg bg-gradient-to-r from-indigo-500 to-blue-500 text-white flex gap-2">
          <div>
          <h5>Verified</h5>
          <h3 className="font-extrabold text-2xl">{studentCount.verified}</h3>
          </div>
          <div>
            <h5>Guest Registration</h5>
            <h3 className="font-extrabold text-2xl">
              {studentCount.guestCount[0].total_guest}
            </h3>
          </div>
        </div>
        <div className="px-4 py-3 rounded-lg shadow-lg bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white">
          <h5>Total Collection</h5>
          <h3 className="font-extrabold text-2xl">
            {studentCount.totalCollection} TK
          </h3>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 my-2">
        {studentCount.mediumCount.map((medium, idx) => (
          <div
            key={idx}
            className={`px-4 py-3 rounded-lg shadow-lg bg-gradient-to-r from-yellow-600 to-red-600 text-white`}
          >
            <h5>{medium._id}</h5>
            <h3 className="font-extrabold text-2xl">
              Count: {medium.total} Amount: {medium.amount} TK
            </h3>
          </div>
        ))}
      </div>
      <Graph></Graph>
    </div>
  );
};

export default Stats;
