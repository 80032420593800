import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import Main from "../Layout/Main";
import About from "../Pages/About/About";
import AllRegistration from "../Pages/Dashboard/AllRegistration/AllRegistration";
import UpdateRegistration from "../Pages/Dashboard/AllRegistration/UpdateRegistration";
import Pending from "../Pages/Dashboard/Pending/Pending";
import Stats from "../Pages/Dashboard/Stats";
import Booking from "../Pages/Home/Booking";
import Home from "../Pages/Home/Home";
import Rr from "../Pages/Home/Rr";
import Login from "../Pages/Login/Login";
import ProgramDetails from "../Pages/ProgramDeatails/ProgramDetails";
import ProgramDeatails from "../Pages/ProgramDeatails/ProgramDetails";
import Students from "../Pages/Student/Students";
import AdminRoute from "./AdminRoute";
import PrivateRoutes from "./PrivateRoutes";
import Tshirt from "../Pages/Dashboard/Tshirt/Tshirt";
import Directory from "../Pages/Dashboard/Directory/Directory";
import AllStudent from "../Pages/Dashboard/AllStudent/AllStudent";
import Entry from "../Pages/Dashboard/Entry/Entry";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Main></Main>,
        children: [
            {
                path: '/',
                element: <Home></Home>
            },
            {
                path:'/about',
                element: <ProgramDetails></ProgramDetails>
            },
            {
                path:'/students',
                element: <Students></Students>
            },
            {
                path:'/registration',
                element: <Booking></Booking>
            },
            {
                path:'/login',
                element: <Login></Login>
            },
            {
                path:'/amit',
                element: <Rr></Rr>
            },
        ]
    },
    {
        path: '/dashboard',
        element: <PrivateRoutes> <DashboardLayout></DashboardLayout> </PrivateRoutes>,
        children:[
            {
                path:'/dashboard',
                element: <AdminRoute><Stats></Stats></AdminRoute>
            },
            {
                path:"/dashboard/user",
                element: <AdminRoute><AllRegistration></AllRegistration></AdminRoute> 
            },
            {
                path:"/dashboard/pending",
                element: <AdminRoute><Pending></Pending></AdminRoute> 
            },
            {
                path: '/dashboard/update/:id',
                loader: ({params}) => fetch(`https://rani-bhobani-server.vercel.app/registration/${params.id}`,{
                    headers: {
                        authorization: `bearer ${localStorage.getItem("accessToken")}`,
                      },
                }),
                element:<AdminRoute><UpdateRegistration></UpdateRegistration></AdminRoute>
            },
            {
                path: '/dashboard/tshirt',
                element:<AdminRoute><Tshirt></Tshirt></AdminRoute>
            },
            {
                path: '/dashboard/directory',
                element:<AdminRoute><Directory></Directory></AdminRoute>
            },
            {
                path: '/dashboard/student',
                element:<AdminRoute><AllStudent></AllStudent></AdminRoute>
            },
            {
                path: '/dashboard/entry',
                element:<AdminRoute><Entry></Entry></AdminRoute>
            },
        ]
    }
])