import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Rani from "../../assets/rani.jpg";
const Info = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mt-10 items-center">
      <div className="px-3">
        {/* <LazyLoadImage
        alt=""
        effect="blur"
        src={Rani}
        
        data-aos="fade-right"
        className="w-1/2 h-600 object-cover mx-auto"
        /> */}

        
        <img
          data-aos="fade-right"
          src={Rani}
          alt=""
          className="w-1/2 h-600 object-cover mx-auto"
        />
      </div>
      <div className="px-2">
        <h2 className="text-2xl font-bold text-orange-400" data-aos="fade-up">
          {" "}
          আমাদের বিদ্যালয়
        </h2>
        <p data-aos="fade-up" data-aos-delay="300">
          টাঙ্গাইল জেলার অর্ন্তগত মধুপুর উপজেলার মধুপুর বাসষ্ট্যান্ডের ১ কি.মি.
          উত্তরে ময়মনসিংহ- টাঙ্গাইল মহাসড়কের পাশে মনোরম পরিবেশে বিদ্যালয়টি
          অবস্থিত। বিদ্যালয়টি ১৯৩৯ সালে নাটোরের মহারাণী “রাণী ভবানী” প্রতিষ্ঠা
          করেন। প্রতিষ্ঠাকালীন সময় হতে বিদ্যালয়টি সুনামের সাথে পাঠদান করে আসছে।
          ২০০০ সালে বিদ্যালয় হতে ঢাকা বোর্ডের সম্মিলিত মেধা তালিকায় ২০ তম স্থান
          সহ ২০০২ সালে জাতীয় পর্যায়ে শ্রেষ্ঠ বিদ্যালয় হওয়ার গৌরব অর্জন করে। ২০০৯
          সালে বিদ্যালয়টি মডেল বিদ্যালয়ে রূপান্তর এবং ২০১৬ সালে বর্তমান সরকার
          জাতীয় করন করে।
        </p>
      </div>
    </div>
  );
};

export default Info;
