import React, { useContext } from "react";
import { AuthContext } from "../Contexts/AuthProvider";
import useAdmin from "../hooks/useAdmin";
import { HiHome, HiMenuAlt1, HiUser,HiBan,HiOutlineBookmark } from "react-icons/hi";
import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import Header from "../Pages/Shared/Header/Header";
import Footer from "../Pages/Shared/Footer/Footer";
const DashboardLayout = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [open,setOpen] = useState(true);
  const menus = [
    { name: "Dashboard", link: "/dashboard", icon: <HiHome></HiHome> },
    { name: "All Registration", link: "/dashboard/user", icon: <HiUser></HiUser> },
    { name: "Pending Registration", link: "/dashboard/pending", icon: <HiBan></HiBan> },
    { name: "T-shirt", link: "/dashboard/tshirt", icon: <HiOutlineBookmark></HiOutlineBookmark> },
    { name: "Directory", link: "/dashboard/directory", icon: <HiOutlineBookmark></HiOutlineBookmark> },
    { name: "Students", link: "/dashboard/student", icon: <HiOutlineBookmark></HiOutlineBookmark> },
    { name: "Entry", link: "/dashboard/entry", icon: <HiOutlineBookmark></HiOutlineBookmark> },
  ];
  return (
    <section className="flex gap-6">
      <div className={`bg-[#0e0e0e]  h-screen overflow-auto px-4 text-white ${open ? 'w-72' : 'w-14'} duration-500`}>
        <div className="py-3 flex justify-end">
          <HiMenuAlt1 size={26} className="cursor-pointer" onClick={()=> setOpen(!open)}></HiMenuAlt1>
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus.map((menu, i) => 
            <Link key={i} to={menu.link} className="group flex item-center gap-3 p-2 hover:bg-gray-400">
                <div>{menu.icon}</div>
              <h2 style={{transitionDelay: `${i+3}00ms`}} className={`whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}>{menu.name}</h2>
              <h2 className={`${open && "hidden"} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu.name}</h2>
            </Link>
          )}

          {/* {
            isAdmin && (
                <>
                    hi
                </>
            )
          } */}
        </div>
      </div>
      <div className={`m-3 text-xl text-gray-900 font-semibold ${open ? 'w-[calc(100%-18rem)]' : 'w-[calc(100%-3.5rem)]'}`}>
            <Header></Header>
            <Outlet></Outlet>
            <Footer></Footer>
      </div>
    </section>
  );
};

export default DashboardLayout;
