import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
           <p className='text-center my-5'> Copyrights &copy; 2023. All Rights Reserved <a href='https://amitksingha.netlify.app/' target="_blank" className='text-red-400'>Amit Kumar Singha</a></p>
        </div>
    );
};

export default Footer;