import React, { useEffect, useState } from "react";

const Timer = () => {
  //date : month/date/year
  const [countdownDate, setCountdownDate] = useState(
    new Date("03/26/2023").getTime()
  );
  const [userCount,setUserCount] = useState(0);
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    setInterval(() => setNewTime(), 1000);
    fetch('https://rani-bhobani-server.vercel.app/user-count')
    .then(res => res.json())
    .then(res => setUserCount(res))
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({ days: days, hours: hours, minutes, seconds });
    }
  };

  return (
    <div className="text-center">
      <h2 className="text-red-600 font-bold">রেজিস্ট্রেশন ডেডলাইন</h2>
      <h3 className="text-red-600 font-bold text-4xl"> March 25, 2023</h3>
      <h4 className="text-cyan-600 font-bold text-xl">Total Registration : <span className="text-cyan-400">{userCount.userCount}</span></h4>
      {/* <div className="countdown-wrapper">
        <div className="time-section">
          <div className="time">{state.days || "0"}</div>
          <small className="time-text">Days</small>
        </div>
        <div className="time-section">
          <div className="time">:</div>
        </div>
        <div className="time-section">
          <div className="time">{state.hours || "00"}</div>
          <small className="time-text">Hours</small>
        </div>
        <div className="time-section">
          <div className="time">:</div>
        </div>
        <div className="time-section">
          <div className="time">{state.minutes || "00"}</div>
          <small className="time-text">Minutes</small>
        </div>
        <div className="time-section">
          <div className="time">:</div>
        </div>
        <div className="time-section">
          <div className="time">{state.seconds || "00"}</div>
          <small className="time-text">Seconds</small>
        </div>
      </div> */}
      <div className="countdown-wrapper">
        <div className="time-section">
          <div className="time">00</div>
          <small className="time-text">Days</small>
        </div>
        <div className="time-section">
          <div className="time">:</div>
        </div>
        <div className="time-section">
          <div className="time">00</div>
          <small className="time-text">Hours</small>
        </div>
        <div className="time-section">
          <div className="time">:</div>
        </div>
        <div className="time-section">
          <div className="time">00</div>
          <small className="time-text">Minutes</small>
        </div>
        <div className="time-section">
          <div className="time">:</div>
        </div>
        <div className="time-section">
          <div className="time">00</div>
          <small className="time-text">Seconds</small>
        </div>
      </div>
    </div>
  );
};

export default Timer;
