import React from "react";
import CountUp from "react-countup";
import {
  FaUserGraduate,
  FaUserEdit,
  FaHouseUser,
  FaLaptopCode,
} from "react-icons/fa";
import Bgbanner from "../../assets/3.jpg";
import "./Home.css";

const Notice = () => {
  return (
    <section
      style={{ backgroundImage: `url(${Bgbanner})` }}
      className="pb-10 mt-10 notice"
    >
        <h2 className="text-center text-2xl font-medium mb-10">স্কুল &nbsp; পরিসংখ্যান  </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col justify-center items-center" data-aos="fade-up" data-aos-delay="100">
          <CountUp end={1800} duration={5} />
          <FaUserGraduate className="inline-block text-4xl"></FaUserGraduate>
          <p> ছাত্রছাত্রী </p>
        </div>
        <div className="flex flex-col justify-center items-center" data-aos="fade-up" data-aos-delay="200">
          <CountUp end={26} duration={5} />
          <FaUserEdit className="inline-block text-4xl"></FaUserEdit>
          <p> শিক্ষক </p>
        </div>
        <div className="flex flex-col justify-center items-center" data-aos="fade-up" data-aos-delay="300">
          <CountUp end={28} duration={5} />
          <FaHouseUser className="inline-block text-4xl"></FaHouseUser>
          <p> ক্লাস রুম </p>
        </div>
        <div className="flex flex-col justify-center items-center" data-aos="fade-up" data-aos-delay="400">
          <CountUp end={18} duration={5} />
          <FaLaptopCode className="inline-block text-4xl"></FaLaptopCode>
          <p> কম্পিউটার </p>
        </div>
      </div>
    </section>
  );
};

export default Notice;
