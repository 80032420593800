import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Man from "../../assets/man.png";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import format from 'date-fns/format';
import { useEffect } from "react";
import Loader from "../Shared/Loader/Loader";

const Rr = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();
      const [image, setImage] = useState(Man);
      const [showModal, setShowModal] = useState(false);
      const [payAmount, setPayAmount] = useState(1020);
      const [totalDonation,setDonation] = useState(0);
      const [totalPerson,setTotalPerson] = useState(0);
      const [methods, setMethods] = useState("Bkash");
      const [loading, setLoading] = useState(false);
      const [calender,setCalender] = useState('');
      const [open,setOpen] = useState(false);
      const [mediums,setMediums] = useState([]);
      const [uploadImage,setUploadImage] = useState('');
      const imageHostKey = process.env.REACT_APP_imgbb_key;
      const navigate = useNavigate();
    
      const handleAddBooking = (data) => {
        Swal.fire({
          title: "Do you want to save the changes?",
          text: `Full Name: ${data.english_name}<=>
          Full Name(Bangla): ${data.bangla_name}<=>
          Blood Group: ${data.blood_group}<=>
          Gender: ${data.gender}<=>
          Date of Birth: ${calender}<=>
          Email: ${data.email}<=>
          Phone: ${data.phone}<=>
          Present Address: ${data.present_address}<=>
          Permanent Address: ${data.permanent_address}<=>
          Batch: ${data.batch}<=>
          Group: ${data.group}<=>
          Occupation: ${data.occupation}<=>
          Children: ${data.children}<=>
          Guest: ${data.guest}<=>
          Payable Amount: ${payAmount}<=>
          Payment Medium: ${data.payment_medium}<=>
          Transaction Id: ${data.trx_id}<=>
          T-shirt Size: ${data.shirt_size}<=>
          Donation: ${data.donation}<=>
          `,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            setLoading(true);
    
            
            const image = data.image[0];
            const formData = new FormData();
            formData.append("file", image);
            // formData.append('upload_preset','ranibhobani')
            // formData.append('cloud_name','dtyj9vjg2')
            formData.append('upload_preset','gtf4mjot')
        formData.append('cloud_name','dliuifi22')
        

        

        const url = `https://api.cloudinary.com/v1_1/dliuifi22/image/upload`;
            fetch(url, {
              method: "POST",
              body: formData,
            })
              .then((res) => res.json())
              .then((imageData) => {
                // console.log(imageData);
                if (imageData.url) {
                  const bookings = {
                    english_name: data.english_name,
                    bangla_name: data.bangla_name,
                    image: imageData.url,
                    blood_group: data.blood_group,
                    gender: data.gender,
                    dob: calender,
                    email: data.email,
                    phone: data.phone,
                    present_address: data.present_address,
                    permanent_address: data.permanent_address,
                    batch: data.batch,
                    group: data.group,
                    occupation: data.occupation,
                    children: data.children,
                    guest: data.guest,
                    payable_amount: payAmount,
                    payment_medium: data.payment_medium,
                    trx_id: data.trx_id,
                    shirt_size: data.shirt_size,
                    donation: data.donation,
                    postingTime: Date().toLocaleString(),
                    status: "pending",
                  };
                  fetch("https://rani-bhobani-server.vercel.app/bookings", {
                    method: "POST",
                    headers: {
                      "content-type": "application/json",
                    },
                    body: JSON.stringify(bookings),
                  })
                    .then((res) => res.json())
                    .then((result) => {
                      setLoading(false);
                      Swal.fire("Saved!", "", "success");
                      toast.success(
                        "Your Registration data Submitted Succesfully !"
                      );
                      setImage(Man);
                      reset();
                      navigate("/about");
                    });
                }
              }).catch(err =>{
                console.log(err);
              });
         
    
    
    
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      };
    
      const handleImage = (event) => {
        if (event.target.files && event.target.files[0]) {
          setImage(URL.createObjectURL(event.target.files[0]));
          setUploadImage(event.target.files[0]);
          
        }
      };
    
      const handleAmount = (e) => {
        const person = e.target.value;
        const baseAmount = 1020;
        if (person === "") {
          e.target.value = 0;
          setTotalPerson(0);
          const totalAmount = baseAmount + 520 * parseInt(0) + totalDonation;
          setPayAmount(totalAmount);
        } else if (person > parseInt(5)) {
          e.target.value = 5;
          setTotalPerson(5);
          const totalAmount = baseAmount + 520 * parseInt(5) + totalDonation;
          setPayAmount(totalAmount);
        } else {
          setTotalPerson(person);
          const totalAmount = baseAmount + 520 * parseInt(person) + totalDonation;
          setPayAmount(totalAmount);
        }
      };
      const handleDonation = (e) => {
        const donationAmount = e.target.value;
        const baseAmount = 1020;
        if (donationAmount === "") {
          e.target.value = 0;
          setDonation(0);
          const totalAmount = baseAmount + 520 * parseInt(totalPerson) + parseInt(0);
          setPayAmount(totalAmount);
          console.log(totalAmount);
        }else {
          setDonation(parseInt(donationAmount));
          const totalAmount = baseAmount + 520 * parseInt(totalPerson) + parseInt(donationAmount);
          setPayAmount(totalAmount);
          console.log(totalAmount);
        }
        
      }
      const handleMethod = (e) => {
        const method_name = e.target.value;
        setMethods(method_name);
      };
      const handleSelect = date =>{
        setCalender(format(date,'dd/MM/yyyy'));
        setOpen(false);
      }
     
      useEffect(()=>{
        setLoading(true);
        fetch(`https://rani-bhobani-server.vercel.app/mediums`)
        .then(res => res.json())
        .then(data => {
          setMediums(data);
          setLoading(false);
        });
        setCalender(format(new Date(),'dd/MM/yyyy'))
      },[]);
    
      if(loading){
        return <Loader></Loader>
      }
    
    return (
        <div className="flex mt-10">
      <div
        className="hidden lg:block w-1/2"
        data-aos="zoom-out"
        data-aos-delay="100"
      >
        <iframe
          title="Madhupur Rani bhobani Model High School"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14509.107225965647!2d90.03363422747165!3d24.61414273856873!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb49fe8e28cb54225!2sRani%20Bhabani%20Govt.%20High%20School!5e0!3m2!1sen!2sbd!4v1675503929433!5m2!1sen!2sbd"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="w-full h-full relative"
        ></iframe>
      </div>

      {loading ? (
        <img
          src="loader.gif"
          alt=""
          style={{ width: "100px", height: "100px" }}
        ></img>
      ) : (
        <div
          className="relative w-full lg:w-1/2 text-center px-4 "
          data-aos="zoom-in"
        >
          <h2 className="text-3xl font-semibold text-orange-400">
            রেজিস্ট্রেশন
          </h2>
          <p className="text-xs font-bold text-red-400">
            শৈশব ফিরুক সবার প্রানে <br /> স্কুলের বাঁধন জাগুক মনে
          </p>
          <div
            className="absolute right-3 top-6 flex flex-col items-center bg-slate-400 px-2 py-2 cursor-pointer animate__animated animate__bounce animate__slower animate__infinite"
            onClick={() => setShowModal(true)}
          >
            <FaInfoCircle></FaInfoCircle>
            <span>Rules </span>
          </div>
          {showModal ? (
            <div className="absolute border-0 rounded-lg shadow-2xl flex flex-col w-full bg-white outline-none focus:outline-none z-[1]">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                <h3 className="text-3xl font=semibold">Rules and Regulation</h3>
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
              <div className="p-6 text-left">
                <h3 className="font-bold text-2xl">General Info</h3>
                <p className="text-orange-500 text-sm">
                  <span className="text-4xl text-pink-500">*</span>{" "}
                  <span className="text-2xl">
                    Registration Fee: <b>1020 BDT</b>- Per Person && Guest Fee:{" "}
                    <b>520 BDT</b> - Per Person
                  </span>
                </p>
                <p className="text-orange-500 text-sm">
                  <span className="text-4xl text-pink-500">*</span> অতিথি হিসেবে
                  বাবা / মা /স্বামী / স্ত্রী / সন্তান{" "}
                  <span className="text-red-500">(৫ বছরের অধিক বয়সী)</span> কে
                  গণ্য করা হবে ।
                </p>
                <p className="text-orange-500 text-sm">
                  <span className="text-4xl text-pink-500">*</span>
                  <span className="text-red-500">৫ বছরের অধিক বয়সী</span> দের
                  জন্য অতিথি রেজিস্ট্রেশন করতে হবে ।
                </p>
                <p className="text-orange-500 text-sm">
                  <span className="text-4xl text-pink-500">*</span>{" "}
                  <span className="text-red-500">৫ বছরের কম বয়সী</span> সন্তান
                  বাবা মার সাথে অতিথি রেজিস্ট্রেশন ছাড়া আসতে পারবে ।
                </p>

                <p className="text-orange-500 text-sm">
                  <span className="text-4xl text-pink-500">*</span> স্কুল এর
                  ছাত্র / ছাত্রী অতিথি হতে পারবে না।
                </p>
                <h3 className="font-bold text-2xl">Payment Procedure</h3>
                <ul>
                  <li>
                    01. Go to your <b>bKash/Nagad/Rocket</b> Mobile App
                  </li>
                  <li>
                    02. Tap on <b>“Send Money”</b> Option
                  </li>
                  <li>
                    03. Enter the <b>bKash/Nagad/Rocket</b> Account Number{" "}
                    <span className="font-bold text-blue-500">
                     01964874212( <b>Bkash</b> ) /  01740793454( <b>Rocket </b> ) / 01740793454( <b>Nagad </b> )
                      
                    </span>
                  </li>
                  <li>
                    04. Enter the amount from the <b>Payable Amount</b> field.{" "}
                    <b>(Ex. 2,525 BDT)</b>
                  </li>
                  <li>
                    05. Enter your “Name-Batch” as <b>‘reference’</b>.{" "}
                    <b>(Ex. johnsmith-1970)</b>{" "}
                  </li>
                  <li>
                    06. Now enter your <b>bKash/Nagad/Rocket</b> Mobile Menu PIN
                  </li>
                  <li>07. Tap and hold to confirm the transaction</li>
                </ul>
                <p>
                  Done! You will receive a <b>bKash/Nagad/Rocket</b> Transaction
                  ID. <b>Save the Transaction ID</b>.
                </p>
              </div>
            </div>
          ) : null}

          <form onSubmit={handleSubmit(handleAddBooking)}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="text-left px-2 my-2">
                <label className="text-black text-xs font-bold"> বাংলা পূর্ণ নাম </label> <br />
                <input
                  type="text"
                  {...register("bangla_name", {
                    required: "Bangla Name is Required",
                  })}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
                {errors.bangla_name && (
                  <p className="text-red-500">{errors.bangla_name.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Full Name (English)</label> <br />
                <input
                  type="text"
                  {...register("english_name", {
                    required: "English Name is Required",
                  })}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
                {errors.english_name && (
                  <p className="text-red-500">{errors.english_name.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Image</label> <br />
                <input
                  type="file"
                  accept="image/*"
                  {...register("image", {
                    required: "Photo is Required",
                  })}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                  onChange={handleImage}
                />
                {errors.image && (
                  <p className="text-red-500">{errors.image.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <div className="border-0 bg-transparent w-full text-black focus:outline-none">
                  <img
                    src={image}
                    alt=""
                    className="w-24 h-24 mx-auto  rounded-md shadow-md object-cover"
                  />
                </div>
              </div>
              <div className="text-left px-2 flex my-2 gap-2">
                <label className="text-black">Blood Group</label> <br />
                <select
                  {...register("blood_group")}
                  className="border-2 border-blue-500 h-7"
                  defaultValue={"O+"}
                >
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
                <label className="text-black">Gender</label> <br />
                <select
                  {...register("gender")}
                  className="border-2 border-blue-500 h-7"
                  defaultValue={"Male"}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="text-left px-2 my-2 relative">
                <label className="text-black">Date of Birth</label> <br />
                <input
                  {...register("dob", {
                    required: "Date of birth  is Required",
                  })}
                  value={calender}
                  readOnly
                  onClick={() => setOpen(open => !open)}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
                {
                  open && <Calendar date={new Date()} onChange={handleSelect}/>
                }
                {errors.dob && (
                  <p className="text-red-500">{errors.dob.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Email</label> <br />
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is Required",
                  })}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Phone</label> <br />
                <input
                  type="number"
                  {...register("phone", { required: "Phone is required" })}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none appearance-none"
                />
                {errors.phone && (
                  <p className="text-red-500">{errors.phone.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Present Address</label> <br />
                <textarea
                  {...register("present_address", {
                    required: "Present Address is required",
                  })}
                  className="w-full border-0 bg-transparent border-b-2 border-b-blue-500  text-black focus:outline-none"
                  style={{ width: "100%" }}
                ></textarea>
                {errors.present_address && (
                  <p className="text-red-500">
                    {errors.present_address.message}
                  </p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Permanent Address</label> <br />
                <textarea
                  {...register("permanent_address", {
                    required: "Permanent Address is required",
                  })}
                  className="w-full border-0 bg-transparent border-b-2 border-b-blue-500  text-black focus:outline-none"
                  style={{ width: "100%" }}
                ></textarea>
                {errors.permanent_address && (
                  <p className="text-red-500">
                    {errors.permanent_address.message}
                  </p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Batch</label> <br />
                <select
                  {...register("batch")}
                  className="border-2 border-blue-500"
                  defaultValue={"O+"}
                >
                  <option value="1939">1939</option>
                  <option value="1940">1940</option>
                  <option value="1941">1941</option>
                  <option value="1942">1942</option>
                  <option value="1943">1943</option>
                  <option value="1944">1944</option>
                  <option value="1945">1945</option>
                  <option value="1946">1946</option>
                  <option value="1947">1947</option>
                  <option value="1948">1948</option>
                  <option value="1949">1949</option>
                  <option value="1950">1950</option>
                  <option value="1951">1951</option>
                  <option value="1952">1952</option>
                  <option value="1953">1953</option>
                  <option value="1954">1954</option>
                  <option value="1955">1955</option>
                  <option value="1956">1956</option>
                  <option value="1957">1957</option>
                  <option value="1958">1958</option>
                  <option value="1959">1959</option>
                  <option value="1960">1960</option>
                  <option value="1961">1961</option>
                  <option value="1962">1962</option>
                  <option value="1963">1963</option>
                  <option value="1964">1964</option>
                  <option value="1965">1965</option>
                  <option value="1966">1966</option>
                  <option value="1967">1967</option>
                  <option value="1968">1968</option>
                  <option value="1969">1969</option>
                  <option value="1970">1970</option>
                  <option value="1971">1971</option>
                  <option value="1972">1972</option>
                  <option value="1973">1973</option>
                  <option value="1974">1974</option>
                  <option value="1975">1975</option>
                  <option value="1976">1976</option>
                  <option value="1977">1977</option>
                  <option value="1978">1978</option>
                  <option value="1979">1979</option>
                  <option value="1980">1980</option>
                  <option value="1981">1981</option>
                  <option value="1982">1982</option>
                  <option value="1983">1983</option>
                  <option value="1984">1984</option>
                  <option value="1985">1985</option>
                  <option value="1986">1986</option>
                  <option value="1987">1987</option>
                  <option value="1988">1988</option>
                  <option value="1989">1989</option>
                  <option value="1990">1990</option>
                  <option value="1991">1991</option>
                  <option value="1992">1992</option>
                  <option value="1993">1993</option>
                  <option value="1994">1994</option>
                  <option value="1995">1995</option>
                  <option value="1996">1996</option>
                  <option value="1997">1997</option>
                  <option value="1998">1998</option>
                  <option value="1999">1999</option>
                  <option value="2000">2000</option>
                  <option value="2001">2001</option>
                  <option value="2002">2002</option>
                  <option value="2003">2003</option>
                  <option value="2004">2004</option>
                  <option value="2005">2005</option>
                  <option value="2006">2006</option>
                  <option value="2007">2007</option>
                  <option value="2008">2008</option>
                  <option value="2009">2009</option>
                  <option value="2010">2010</option>
                  <option value="2011">2011</option>
                  <option value="2012">2012</option>
                  <option value="2013">2013</option>
                  <option value="2014">2014</option>
                  <option value="2015">2015</option>
                  <option value="2016">2016</option>
                  <option value="2017">2017</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                </select>
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Group</label> <br />
                <select
                  {...register("group")}
                  className="border-2 border-blue-500 w-full"
                  defaultValue={"Science"}
                >
                  <option value="Science">Science</option>
                  <option value="Arts">Arts</option>
                  <option value="Commerce">Commerce</option>
                  <option value="Vocational">Vocational</option>
                </select>
              </div>

              <div className="text-left px-2 my-2">
                <label className="text-black">
                  Number of Children ({" "}
                  <span className="text-red-300">3-5 years</span> ){" "}
                </label>{" "}
                <br />
                <input
                  type="number"
                  {...register("children")}
                  min={0}
                  defaultValue="0"
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">
                  Accompanied Person ({" "}
                  <span className="text-red-300">above 5 years</span> )
                </label>{" "}
                <br />
                <input
                  type="number"
                  {...register("guest")}
                  onKeyUp={handleAmount}
                  min={0}
                  defaultValue="0"
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
              </div>
              <div className="text-left px-2 my-2">
                <p className="text-black">Donation</p>
                <input
                type="number"
                  {...register("donation")}
                  onKeyUp={handleDonation}
                  min={0}
                  defaultValue={0}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
            
              </div>

              <div className="text-left px-2 my-2">
                <p className="text-black">Payable Amount</p>
                <input
                  type="number"
                  {...register("payable_amount")}
                  value={payAmount}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                  readOnly
                />
              </div>
              <div className="text-left px-2 my-2">
                <p className="text-black">Payment Media</p>
                <select
                  {...register("payment_medium")}
                  className="border-2 border-blue-500 w-full"
                  onChange={handleMethod}
                >
                  <option value="Bkash">Bkash</option>
                  <option value="Rocket">Rocket</option>
                  <option value="Nagad">Nagad</option>
                  <option value="Bank">Bank</option>
                </select>
                {methods === "Bkash" ? (
                  <p> Bkash Number : 01964874212 </p>
                ) : methods === "Rocket" ? (
                  <p> Rocket Number : 01740793454</p>
                ) : methods === "Nagad" ? (
                  <p> Nagad Number : 01740793454</p>
                ) : (
                  <div>
                    <p >
                      NCC Bank Ltd <br />
                      Madhupur
                      <br />
                      Routing Number: 160931572
                      <br />
                      Account Number: 00640325000275
                      <br />
                      Account Holder: Madhupur Rani Bhabani MGHS Alumni
                      Association
                      <br />
                    </p>
                  </div>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <p className="text-black">Transaction ID</p>
                <input
                  {...register("trx_id", {
                    required: "Transaction ID is required",
                  })}
                  type="text"
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
                {errors.trx_id && (
                  <p className="text-red-500">{errors.trx_id.message}</p>
                )}
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">T-shirt Size</label> <br />
                <select
                  {...register("shirt_size")}
                  className="border-2 border-blue-500 w-full"
                  defaultValue={"s-chest-36-length-26"}
                >
                  <option value="s-chest-36-length-26">
                    S (Chest-36", Length-26")
                  </option>

                  <option value="m-chest-38-length-27">
                    M (Chest-38", Length-27")
                  </option>

                  <option value="l-chest-40-length-28">
                    L (Chest-40", Length-28")
                  </option>

                  <option value="xl-chest-42-length-29">
                    XL (Chest-42", Length-29")
                  </option>

                  <option value="xxl-chest-44-length-29-5">
                    XXL (Chest-44", Length-29.5")
                  </option>

                  <option value="xxxl-chest-46-and-quot-length-30-and-quot">
                    XXXL (Chest-46", Length-30")
                  </option>
                </select>
              </div>
              <div className="text-left px-2 my-2">
                <label className="text-black">Occupation</label> <br />
                <input
                  type="text"
                  {...register("occupation", {
                    required: "Occupation is required",
                  })}
                  className="border-0 bg-transparent border-b-2 border-b-blue-500 w-full text-black focus:outline-none"
                />
                {errors.occupation && (
                  <p className="text-red-500">{errors.occupation.message}</p>
                )}
              </div>
              
            </div>
            <button
              type="submit"
              className="bg-orange-400 px-4 py-2 rounded-md text-white drop-shadow-lg"
            >
              Confirm Registration
            </button>
          </form>
        </div>
        // <div className="w-full">
        //     <h3 className="text-red-500 text-center font-bold text-4xl">Registration Closed</h3>
        //     <iframe src="https://giphy.com/embed/JJfkt7QEKRvMY6JwDL"  className="w-full" allowFullScreen title="closed"></iframe><p><a href="https://giphy.com/gifs/cbc-kims-convenience-closing-time-ok-see-you-JJfkt7QEKRvMY6JwDL">via GIPHY</a></p>
        // </div>
      )}
    </div>
    );
};

export default Rr;