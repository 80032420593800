import React, { useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { FaBars, FaWindowClose } from "react-icons/fa";
import Logo  from '../../../assets/logo.png';
import { AuthContext } from '../../../Contexts/AuthProvider';
import { FcExport } from "react-icons/fc";
const Header = () => {
    const [open, setOpen] = useState(false);
    const { user,logOut } = useContext(AuthContext);

    const handleLogOut = () => {
      setOpen(!open);
      logOut()
          .then(() => { <Navigate to={'/'}></Navigate> })
          .catch(err => console.log(err));
  }

  
    return (
        <header className='z-[999]'>
        <nav className="py-5 md:flex md:items-center md:justify-between md:place-items-center">
          <div className="flex justify-between place-items-center">
            <Link to={'/'} className="text-3xl font-semibold">
              <img src={'https://i.ibb.co/D4NqN7J/RBMSHS-reunion2023.png'} alt="" className='w-24 object-cover'/>
            </Link>
            <span
              onClick={() => setOpen(!open)}
              className="md:hidden block text-gray-800"
            >
              {open ? <FaWindowClose></FaWindowClose> : <FaBars></FaBars>}
            </span>
          </div>
          <ul
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:bg-transparent  left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-20 bg-[#ffffff] z-[999] sm:drop-shadow-md sm:rounded-md md:drop-shadow-none" : "top-[-490px]"
            }`}
          >
            <li className="mx-4 my-6 md:my-0 ">
              <Link onClick={() => setOpen(!open)} to={'/'} className="text-lg hover:text-orange-400 text-gray-700 duration">
                Home
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)} to={'/about'} className="text-lg hover:text-orange-400 text-gray-700 duration">
              Programme Schedule
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)} to={'/students'} className="text-lg hover:text-orange-400 text-gray-700 duration">
                All Registered Student
              </Link>
            </li>
            
            {user ? 
            <>
              <li className="mx-4 my-6 md:my-0">
              <Link onClick={handleLogOut} className="text-lg hover:text-orange-400 text-gray-700 duration">
                <span className='flex items-center gap-1 text-rose-500'><img src={user?.photoURL} alt="" className='w-8 h-8' title={user.displayName}/> <span>LogOut  </span><FcExport></FcExport></span>
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)} to={'/dashboard'} className="text-lg hover:text-orange-400 text-gray-700 duration">
                Dashboard
              </Link>
            </li>
            </> 
            : 
            <>

            </>}
            <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)} to={'/registration'} className="text-lg hover:text-white duration bg-orange-400 hover:bg-orange-500 text-white px-4 py-2 rounded-md shadow-md hover:shadow-lg">
                Registration
              </Link>
            </li>
            {/* <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)} to={'/portfolio'} className="text-lg hover:text-orange-400 text-gray-700 duration">
                Portfolio
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)} to={'/blog'} className="text-lg hover:text-orange-400 text-gray-700 duration">
                Blog
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link onClick={() => setOpen(!open)}
                to={"/contact"}
                className="text-lg hover:text-orange-300 text-gray-700 duration"
              >
                Contact
              </Link>
            </li> */}
          </ul>
        </nav>
      </header>
    );
};

export default Header;