import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Loader from "../../Shared/Loader/Loader";
import * as XLSX from 'xlsx/xlsx.mjs';

const Tshirt = () => {
  const {
    data: tshirt = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["tshirt"],
    queryFn: async () => {
      // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
      const res = await fetch(`https://rani-bhobani-server.vercel.app/tshirt`, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });
  const excelExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(tshirt);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "Rani Bhobani.xlsx")
  };

  if (isLoading) {
    return <Loader></Loader>;
  }
  return (
    <div>
        <button className='btn btn-outline px-4 py-2 mx-auto  text-white rounded-md shadow-lg block bg-red-500' onClick={excelExport}>Export to Excel</button>
      <table className="w-full">
        <thead>
          <tr>
            <th>SL</th>
            <th>Size</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {tshirt.map((shirt, idx) => (
            <tr
              key={idx}
              className="backdrop-blur-md bg-blue-200 border-b-4 text-black"
            >
              <td>{idx + 1}</td>
              <td>{shirt._id}</td>
              <td>{shirt.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tshirt;
