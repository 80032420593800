import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight, FaHandPointRight } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import 'animate.css';

const Hero = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div>
      <p className="text-base font-bold text-gray-700 my-3">
          মধুপুর রাণী ভবানী সরকারি মডেল উচ্চ বিদ্যালয়
        </p>
        <h1 className="text-4xl text-blue-500">
          পুনর্মিলনী ২০২৩
        </h1>
        <h6 className="font-semibold text-lg mt-3 text-red-400">
          <Typewriter
            options={{
              strings: ["শৈশব ফিরুক সবার প্রাণে", "স্কুলের বাঁধন জাগুক মনে ..."],
              autoStart: true,
              loop: true,
            }}
          />
        </h6>
        
        <div className="mt-3">
          <Link to={'/registration'} className="inline-block bg-orange-400 text-white px-6 py-2 rounded-md">
            <span className="flex gap-4 items-center"><span>রেজিস্ট্রেশন করুন</span> <FaHandPointRight className="animate__animated animate__shakeX animate__slower animate__infinite animate__delay-2s"></FaHandPointRight> </span> 
          </Link>
          {/* <Link to={'/about'} className="inline-block ml-0 md:ml-3 px-6 py-2 hover:bg-orange-400 hover:text-white rounded-md duration-75">
            <span className="flex gap-2 items-center">
              আরও জানুন <FaArrowRight></FaArrowRight>
            </span>{" "}
          </Link> */}
        </div>
      </div>
      <div>
        {/* <iframe
          title="school"
          src="https://embed.lottiefiles.com/animation/27637"
          className="w-1/2 mx-auto"
        ></iframe> */}
        <img src="https://i.ibb.co/GcrhDyB/education.gif" alt="" className="w-1/2 mx-auto"/>
       
      </div>
    </div>
  );
};

export default Hero;
