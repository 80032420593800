import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../../Shared/Loader/Loader';

const Pending = () => {
    const {
        data: alumnis = [],
        refetch,
        isLoading,
      } = useQuery({
        queryKey: ["alumnis"],
        queryFn: async () => {
          // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
          const res = await fetch(
            `https://rani-bhobani-server.vercel.app/alumnis?batch=${'all'}`,
            {
              headers: {
                authorization: `bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          const data = await res.json();
        
          return data.result.reverse();
        },
      });
      const handleVerify = (id) => {
        // console.log(id);
        Swal.fire({
          title: "Do you want to Confirm the Registration?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            fetch(
              `https://rani-bhobani-server.vercel.app/verifyRegistration/${id}`,
              {
                method: "PUT",
                headers: {
                  authorization: `bearer ${localStorage.getItem("accessToken")}`,
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.modifiedCount > 0) {
                  toast.success("Registration successfully Verified.");
                  refetch();
                }
              });
          } else if (result.isDenied) {
            Swal.fire("Registration is not verified", "", "info");
          }
        });
      };
      const handleDelete = (id) => {
        // console.log(id);
        Swal.fire({
          title: "Do you want to Delete!",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Delete",
          denyButtonText: `Don't Delete`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            fetch(`https://rani-bhobani-server.vercel.app/booking/${id}`, {
              method: "DELETE",
              headers: {
                authorization: `bearer ${localStorage.getItem("accessToken")}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.deletedCount > 0) {
                  refetch();
                  toast.success(`Registration deleted successfully`);
                }
              });
          } else if (result.isDenied) {
            Swal.fire("Registration is not Deleted", "", "info");
          }
        });
      };
      if (isLoading) {
        return <Loader></Loader>;
      }
    return (
        <div>
            
      <table className="w-full">
        <thead>
          <tr>
            <th>Count</th>
            <th>#</th>
            <th>Name</th>
            <th>Batch</th>
            <th>Phone</th>
            <th>Amount</th>
            <th>Guest</th>
            <th>Media</th>
            <th>TRX ID</th>
            <th>Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {alumnis.filter(al =>al.status === 'pending').map((alumni, idx) => (
            <tr
              key={idx}
              className="backdrop-blur-md bg-blue-200 border-b-4 text-black"
            >
                <td>{idx+1}</td>
              <td>#{alumni.unqId}</td>
              <td className="text-xs">{alumni.bangla_name}</td>
              <td>{alumni.batch}</td>
              <td>{alumni.phone}</td>
              <td>{alumni.payable_amount}</td>
              <td>{alumni.guest}</td>
              <td>{alumni.payment_medium}</td>
              <td>{alumni.trx_id}</td>
              <td className="text-xs font-bold">{alumni.postingTime}</td>
              <td className="py-3">
                {alumni.status === "verified" ? (
                  <button className="px-3 py-2 text-sm text-white bg-green-600">
                    {alumni.status}
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleVerify(alumni._id)}
                      className="px-3 py-2 text-sm text-white bg-yellow-600"
                    >
                      {alumni.status}
                    </button>
                  </>
                )}
                <Link
                  className="px-3 py-2 text-sm text-white bg-indigo-700 ml-2"
                  to={`/dashboard/update/${alumni._id}`}
                >
                  Edit
                </Link>

                <button
                  className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                  onClick={() => handleDelete(alumni._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
    );
};

export default Pending;