import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../Shared/Loader/Loader";

const AllRegistration = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState(0);
  const [batch,setBatch] = useState('all');
  

  const pages = Math.ceil(count / size);
  const {
    data: alumnis = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["alumnis", page, size, batch],
    queryFn: async () => {
      // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
      const res = await fetch(
        `https://rani-bhobani-server.vercel.app/alumnis?batch=${batch}`,
        {
          headers: {
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      const data = await res.json();
      setCount(data.count);
      return data.result.reverse();
    },
  });

  const handleVerify = (id) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Confirm the Registration?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(
          `https://rani-bhobani-server.vercel.app/verifyRegistration/${id}`,
          {
            method: "PUT",
            headers: {
              authorization: `bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.modifiedCount > 0) {
              toast.success("Registration successfully Verified.");
              refetch();
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Registration is not verified", "", "info");
      }
    });
  };
  const handleDelete = (id) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Delete!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://rani-bhobani-server.vercel.app/booking/${id}`, {
          method: "DELETE",
          headers: {
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.deletedCount > 0) {
              refetch();
              toast.success(`Registration deleted successfully`);
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Registration is not Deleted", "", "info");
      }
    });
  };
  if (isLoading) {
    return <Loader></Loader>;
  }

  const handleBatch = (event) => {
    const batchData = event.target.value;
    setBatch(batchData);
  };

  return (
    <div className="w-4/5 md:w-full overflow-x-scroll md:overflow-auto">
      <form>
        <select className="w-full border px-2 py-3" defaultValue={"all"} onChange={handleBatch}>
          <option value="all">
            All Batch
          </option>
          <option value="1939">1939</option>
          <option value="1940">1940</option>
          <option value="1941">1941</option>
          <option value="1942">1942</option>
          <option value="1943">1943</option>
          <option value="1944">1944</option>
          <option value="1945">1945</option>
          <option value="1946">1946</option>
          <option value="1947">1947</option>
          <option value="1948">1948</option>
          <option value="1949">1949</option>
          <option value="1950">1950</option>
          <option value="1951">1951</option>
          <option value="1952">1952</option>
          <option value="1953">1953</option>
          <option value="1954">1954</option>
          <option value="1955">1955</option>
          <option value="1956">1956</option>
          <option value="1957">1957</option>
          <option value="1958">1958</option>
          <option value="1959">1959</option>
          <option value="1960">1960</option>
          <option value="1961">1961</option>
          <option value="1962">1962</option>
          <option value="1963">1963</option>
          <option value="1964">1964</option>
          <option value="1965">1965</option>
          <option value="1966">1966</option>
          <option value="1967">1967</option>
          <option value="1968">1968</option>
          <option value="1969">1969</option>
          <option value="1970">1970</option>
          <option value="1971">1971</option>
          <option value="1972">1972</option>
          <option value="1973">1973</option>
          <option value="1974">1974</option>
          <option value="1975">1975</option>
          <option value="1976">1976</option>
          <option value="1977">1977</option>
          <option value="1978">1978</option>
          <option value="1979">1979</option>
          <option value="1980">1980</option>
          <option value="1981">1981</option>
          <option value="1982">1982</option>
          <option value="1983">1983</option>
          <option value="1984">1984</option>
          <option value="1985">1985</option>
          <option value="1986">1986</option>
          <option value="1987">1987</option>
          <option value="1988">1988</option>
          <option value="1989">1989</option>
          <option value="1990">1990</option>
          <option value="1991">1991</option>
          <option value="1992">1992</option>
          <option value="1993">1993</option>
          <option value="1994">1994</option>
          <option value="1995">1995</option>
          <option value="1996">1996</option>
          <option value="1997">1997</option>
          <option value="1998">1998</option>
          <option value="1999">1999</option>
          <option value="2000">2000</option>
          <option value="2001">2001</option>
          <option value="2002">2002</option>
          <option value="2003">2003</option>
          <option value="2004">2004</option>
          <option value="2005">2005</option>
          <option value="2006">2006</option>
          <option value="2007">2007</option>
          <option value="2008">2008</option>
          <option value="2009">2009</option>
          <option value="2010">2010</option>
          <option value="2011">2011</option>
          <option value="2012">2012</option>
          <option value="2013">2013</option>
          <option value="2014">2014</option>
          <option value="2015">2015</option>
          <option value="2016">2016</option>
          <option value="2017">2017</option>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
        </select>
      </form>
      <p>Total: {alumnis.length}</p>
      <table className="w-full">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Batch</th>
            <th>Phone</th>
            <th>Amount</th>
            <th>Guest</th>
            <th>Media</th>
            <th>Tshirt Size</th>
            <th>TRX ID</th>
            <th>Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {alumnis.map((alumni, idx) => (
            <tr
              key={idx}
              className="backdrop-blur-md bg-blue-200 border-b-4 text-black"
            >
              <td>#{alumni.unqId}</td>
              <td className="text-xs">{alumni.bangla_name}</td>
              <td>{alumni.batch}</td>
              <td>{alumni.phone}</td>
              <td>{alumni.payable_amount}</td>
              <td>{alumni.guest}</td>
              <td>{alumni.payment_medium}</td>
              <td>{alumni.shirt_size}</td>
              <td>{alumni.trx_id}</td>
              <td className="text-xs font-bold">{alumni.postingTime}</td>
              <td className="py-3">
                {alumni.status === "verified" ? (
                  <button className="px-3 py-2 text-sm text-white bg-green-600">
                    {alumni.status}
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleVerify(alumni._id)}
                      className="px-3 py-2 text-sm text-white bg-yellow-600"
                    >
                      {alumni.status}
                    </button>
                  </>
                )}
                <Link
                  className="px-3 py-2 text-sm text-white bg-indigo-700 ml-2"
                  to={`/dashboard/update/${alumni._id}`}
                >
                  Edit
                </Link>

                <button
                  className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                  onClick={() => handleDelete(alumni._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        {/* <p>Current page: {page + 1}</p>
        <label htmlFor="">Per Page : </label>
        <select className="border px-4 py-2" onChange={event => setSize(event.target.value)}>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        </select>
        <br />
        {
          
          [...Array(pages).keys()].map(number => <button key={number} className={`px-4 py-2 rounded-md mr-2 my-2 text-white ${page === number ? 'bg-orange-400' : 'bg-blue-500'}`} onClick={()=> setPage(number)} > {number+1}</button>)
        } */}
      </div>
    </div>
  );
};

export default AllRegistration;
