import React from 'react';
import Booking from './Booking';
import Hero from './Hero';
import Info from './Info';
import Meetings from './Meetings';
import Notice from './Notice';
import Timer from './Timer';

const Home = () => {
    return (
        <div>
            <Hero></Hero>
            <Timer></Timer>
            <Info></Info>
            <Notice></Notice>
            <Meetings></Meetings>
            <Booking></Booking>
        </div>
    );
};

export default Home;