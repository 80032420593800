import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Meetings = () => {
  return (
    <div>
        <h2 className="text-2xl font-bold text-orange-400 text-center"> গ্যালারি </h2>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/6ZmzC6F/meeting5.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/KVF2Qks/meeting7.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/PM1M39b/meeting10.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/JRDWxMZ/meeting9.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/yfxy8LB/meting1.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/60X8XZL/meeting2.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/pQt52xx/meeting4.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/b17xJQV/meeting6.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/ZVKtXQV/meeting8.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src="https://i.ibb.co/Zf3RdC4/meeting3.jpg"  effect="blur" alt="Rani Bhobani"/>
        </SwiperSlide>
        
      </Swiper>
    </div>
  );
};

export default Meetings;
