import { useQuery } from "@tanstack/react-query";
import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Graph = () => {
  const {
    data: totalCount,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["totalCount"],
    queryFn: async () => {
      try {
        const res = await fetch("https://rani-bhobani-server.vercel.app/year-wise-count", {
          headers: {
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const data = await res.json();

        return data;
      } catch (error) {}
    },
  });

  if (isLoading) {
    return <h2>Loading...</h2>;
  }
  const totalData = totalCount;
  // console.log(totalData)
  return (
    <div className="my-4">
      <h3 className="text-3xl font-bold my-3">Batch Wise Registration</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={totalData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey="total" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
